import Sentry from "@/sentry";
import { AuthService } from "@/util/auth.service";
import StorageService from "@/util/storage.service";
import mapTranslationToCode from "@/util/translation";

const state = {
  identity: StorageService.getIdentity(),
  cardTerminal: StorageService.getCardTerminal(),
  deviceGateway: StorageService.getDeviceGateway(),
  nvzToken: StorageService.getNVZToken()
};

const getters = {
  currentUser(state) {
    return state.identity ? state.identity.user : null;
  },
  getBranchId(state) {
    return state.identity ? state.identity.supportBranchId : null;
  },
  isTransactionAllowed(state) {
    return state.cardTerminal
      ? state.cardTerminal.hasTransactionsAllowed
      : false;
  },
  isPairingActive(state) {
    return state.deviceGateway ? state.deviceGateway.pairingActive : false;
  }
};

const actions = {
  async login({ commit, dispatch }, credentials) {
    let response;
    try {
      commit("setLoading", true, { root: true });
      response = await AuthService.login(credentials);
      commit("storeIdentity", response.data);
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorAuthenticate",
        error
      );

      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return response;
  },

  logout({ commit }) {
    commit("clearIdentity");
    commit("clearNVZToken");
    commit("packet/resetState", null, { root: true });
    commit("payment/resetState", null, { root: true });
    commit("scanned/resetState", null, { root: true });
  },

  async forgotPassword({ commit, dispatch }, email) {
    let response;
    try {
      commit("setLoading", true, { root: true });
      response = await AuthService.forgotPassword(email);
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorPasswordRecovery",
        error
      );

      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return response;
  },

  async getNVZToken({ commit, dispatch }, credentials) {
    try {
      commit("setLoading", true, { root: true });
      const response = await AuthService.getNVZToken(credentials);
      commit("storeNVZToken", response.data);

      return await dispatch("login", credentials);
    } catch (error) {
      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
  }
};

const mutations = {
  storeIdentity(state, data) {
    const identity = data.identity;
    const token = data.token.accessToken;
    const refreshToken = data.token.refreshToken;
    const cardTerminal = data.cardTerminal;
    const deviceGateway = data.deviceGateway;

    state.identity = identity;
    state.cardTerminal = cardTerminal;
    state.deviceGateway = deviceGateway;

    StorageService.saveIdentity(identity);
    StorageService.saveToken(token);
    StorageService.saveRefreshToken(refreshToken);
    StorageService.saveCardTerminal(cardTerminal);
    StorageService.saveDeviceGateway(deviceGateway);

    Sentry.setUser({
      email: identity?.user?.email || identity?.email || null
    });

    this.commit("clearErrors");
  },

  clearIdentity(state) {
    state.identity = null;
    state.cardTerminal = null;
    state.deviceGateway = null;

    StorageService.clearIdentity();
    StorageService.clearToken();
    StorageService.clearRefreshToken();
    StorageService.clearCardTerminal();
    StorageService.clearDeviceGateway();
    this.commit("clearErrors");
  },

  storeNVZToken(state, data) {
    const token = data.access_token;
    state.nvzToken = token;
    StorageService.saveNVZToken(token);
  },

  clearNVZToken() {
    state.nvzToken = null;
    StorageService.clearNVZToken();
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
