import { v4 as uuidv4 } from "uuid";
import ApiService from "@/util/api.service";
import StorageService from "@/util/storage.service";
import { getEnvURL } from "@/util/constants";
import { isAndroid } from "@/util/platform";
import version from "@/util/version";

const NVZ_HEADERS = {
  headers: {
    "X-Client-Platform": "web",
    "X-Client-Version": version,
    "X-Client-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone
  }
};

const setNVZHeader = (name, value) => {
  NVZ_HEADERS.headers[name] = value;
};

const ensureDeviceUuid = () => {
  if (isAndroid) return;

  let deviceUuid = StorageService.getDeviceUuid();

  if (!deviceUuid) {
    deviceUuid = uuidv4();

    StorageService.saveDeviceUuid(deviceUuid);
  }

  setNVZHeader("X-Device-Uuid", deviceUuid);
};

const techInformation = {
  options: {
    tech: {
      osType: "web",
      osVersion: navigator.appVersion,
      osPlatform: navigator.platform,
      clientName: "PPA",
      clientVersion: version,
      resolutionWidth: window.innerWidth,
      resolutionHeight: window.innerHeight,
      timeZone: new Date().getTimezoneOffset() / 60
    }
  }
};

export const AuthService = {
  login(credentials) {
    return ApiService.post("oauth/authenticate", {
      ...credentials,
      ...techInformation
    });
  },

  refreshAuthToken(refreshToken) {
    return ApiService.post("oauth/token", {
      grantType: "refresh_token",
      refreshToken: refreshToken
    });
  },

  getTemporaryNVZToken(nvzToken) {
    setNVZHeader("X-Auth-Token", nvzToken);
    ensureDeviceUuid();

    return ApiService.put(
      `${getEnvURL()}/api/v1/account/sign_in_token`,
      null,
      NVZ_HEADERS
    );
  },

  getNVZToken(credentials) {
    ensureDeviceUuid();

    return ApiService.post(
      `${getEnvURL()}/api/v1/users/authenticate`,
      credentials,
      NVZ_HEADERS
    );
  },

  async forgotPassword(email) {
    const response = await ApiService.post("password-recovery/create-token", {
      email: email
    });

    const token = response.data.token;
    return ApiService.post("password-recovery/send-link", {
      email: email,
      token: token,
      link: `https://admin.packeta.com/password-renewal/new-password?token=${token}&email=${email}`
    });
  }
};

export default AuthService;
