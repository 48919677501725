import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import _ from "lodash";
import i18n from "@/i18n";
import auth from "./auth.module";
import packet from "./packet.module";
import scanned from "./scanned.module";
import payment from "./payment.module";

const isDebug = process.env.NODE_ENV !== "production";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    errors: [],
    featureFlags: {},
    loading: false,
    platform: "",
    device: "",
    isMobile: false
  },

  actions: {
    setErrorOrThrow({ commit }, error) {
      const response = error.response;

      if (response && response.data) {
        if (response.data.error) {
          commit("setErrors", [{ text: response.data.error }]);
        } else {
          commit("setErrors", response.data.errors);
        }
      } else if (_.isArray(error)) {
        commit(
          "setErrors",
          error.map(e => ({ text: e }))
        );
      } else if (_.isObject(error)) {
        commit("setErrors", [error]);
      } else if (_.isString(error)) {
        commit("setErrors", [{ text: error }]);
      } else {
        commit("setErrors", [
          { text: i18n.t("errorMessages.something-has-broken") }
        ]);
        // We want to throw error to Sentry
        throw error;
      }
    },

    showPrintErrorsIfAvailable({ dispatch }, prints) {
      let errors = prints
        .filter(print => print.success == false)
        .map(print =>
          print.errorCode
            ? print.errorCode
            : i18n.t("errorMessages.print-failed")
        );
      if (errors.length) {
        dispatch("setErrorOrThrow", errors);
      }
    },

    resetPacketAndPaymentState({ commit }) {
      commit("packet/resetState");
      commit("payment/resetState");
    },

    setIsMobile({ commit }, isMobile) {
      commit("setIsMobile", isMobile);
    },

    setPlatform({ commit }, platform) {
      commit("setPlatform", platform);
    },

    setDevice({ commit }, device) {
      commit("setDevice", device);
    }
  },

  mutations: {
    setErrors(state, errors) {
      // OMG
      state.errors = _.isArray(errors) ? errors : [errors];
    },

    clearErrors(state) {
      state.errors = [];
    },

    setFeatureFlags(state, flags) {
      state.featureFlags = {
        ...state.featureFlags,
        ...flags
      };
    },

    setLoading(state, loading) {
      state.loading = loading;
    },

    setPlatform(state, platform) {
      state.platform = platform;
    },

    setDevice(state, device) {
      state.device = device;
    },

    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    }
  },

  modules: {
    auth,
    packet,
    scanned,
    payment
  },
  strict: isDebug,
  plugins: isDebug ? [createLogger()] : []
});
