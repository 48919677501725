import { PacketService } from "../util/packet.service";
import i18n from "@/i18n";
import mapTranslationToCode from "@/util/translation";

export const SEARCH_TYPES = {
  BARCODE: "barcode",
  B2C_CONSIGN_PASSWORD: "b2c-consign-password",
  C2C_CONSIGN_PASSWORD: "c2c-consign-password",
  DELIVERY_PASSWORD: "delivery-password",
  CLAIM_FROM_CUSTOMER_PASSWORD: "claim-from-customer-password",
  RETURN_PASSWORD: "return-password",
  B2C_RETURN_PASSWORD: "b2c-return-password",
  C2C_RETURN_PASSWORD: "c2c-return-password",
  SURPRISE_CODE: "surprise-code",
  SURPRISE_CONSIGN_PASSWORD: "surprise-consign-password",
  TRANSACTION: "transaction",
  UNKNOWN: "unknown"
};

export const RECEIVE_PACKET_TYPES = {
  B2C: "b2c",
  C2C: "c2c",
  CLAIM_FROM_CUSTOMER: "claim-from-customer"
};

export const RETURN_SHIPMENT_TYPES = {
  PASSWORD: "password",
  ESHOP_CODE: "eshopCode"
};

export const ERRORS = {
  NOT_UNDELIVERED: "notUndelivered"
};

const getDefaultState = () => {
  return {
    packet: {},
    readyToPick: false,
    readyForUndelivery: false,
    paidInCash: false,
    paidWithDevice: false,
    sender: {},
    packets: [],
    toggleSelectionClicked: false,
    returnShipmentPrints: [],
    reprintId: 0,
    eshopName: null,
    packetReceived: false,
    packetBarcode: 0,
    returnShipmentType: null,
    scannedBarcode: null,
    searchCode: null
  };
};

const state = getDefaultState();

const getters = {};

const actions = {
  async createSearchType({ commit, dispatch }, text) {
    let searchType;
    try {
      commit("setLoading", true, { root: true });
      const response = await PacketService.postSearchType(text);
      searchType = response.data.type;
    } catch (error) {
      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return searchType;
  },

  async getPacketDetail({ commit, dispatch }, text) {
    let packet;
    try {
      commit("setLoading", true, { root: true });
      const response = await PacketService.getPacketDetail(text);
      const data = response.data;
      packet = data.items[0];

      commit("setPaidInCash", false); // set default false paid in cash
      commit("setPacket", packet);
      // Find actual sender for packet
      commit(
        "setSender",
        data.embedded.sender.find(actual => actual.id == packet.senderId)
      );
      commit("refreshReadyToPick");
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorPacketDetail",
        error
      );

      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return packet;
  },

  async getReturnPackets({ commit, dispatch }, text) {
    let sender;
    try {
      commit("setLoading", true, { root: true });
      const response = await PacketService.getReturnPackets(text);
      const data = response.data;
      const packets = data.items;
      sender = data.embedded.sender[0];
      commit("setPackets", packets);
      commit("setSender", sender);

      if (!sender) {
        dispatch("setErrorOrThrow", i18n.t("packetsReturn.no-packets"), {
          root: true
        });
      }
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorReturnPacket",
        error
      );

      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return sender;
  },

  async receiveC2CPacket({ commit, dispatch }, codeAndType) {
    let barcode;
    try {
      commit("setLoading", true, { root: true });
      const response = await PacketService.receiveC2CPacket(
        codeAndType.code,
        codeAndType.type
      );
      const data = response.data;
      const reprintId = data.prints[0].reprintId;
      barcode = data.barcode;

      commit("setPacketReceived", data.success);
      commit("setPacketBarcode", barcode);
      commit("setReprintId", reprintId);
    } catch (error) {
      // TODO set possible errors from different requests.
      error.response.data.errors = mapTranslationToCode(
        "apiErrorC2CReceive",
        error
      );

      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return barcode;
  },

  async getReturnShipment({ commit, dispatch }, textAndType) {
    let eshopName;
    try {
      commit("setLoading", true, { root: true });
      const response = await PacketService.getReturnShipment(textAndType);
      eshopName = response.data.eshopName;
      commit("setEshopName", eshopName);
      commit("setReturnShipmentType", textAndType.returnShipmentType);
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorGetReturnShipment",
        error
      );

      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return eshopName;
  },

  async postReturnShipment({ commit, dispatch }, codeAndCustomer) {
    let response;
    try {
      commit("setLoading", true, { root: true });
      response = await PacketService.postReturnShipment(codeAndCustomer);
      commit("setReturnShipmentPrints", response.data.prints);
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorReturnShipment",
        error
      );

      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return response;
  },

  async deliverPacket({ commit, dispatch }, data) {
    let response;
    try {
      commit("setLoading", true, { root: true });
      response = await PacketService.postPacketDeliver(data);
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorPacketDeliver",
        error
      );

      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return response;
  },

  async undeliverPacket({ commit, dispatch }, params) {
    let response;
    try {
      commit("setLoading", true, { root: true });
      response = await PacketService.postPacketCancelDelivery(params);
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorPacketUnDeliver",
        error
      );

      for (let e of error.response.data.errors) {
        if (e.code === ERRORS.NOT_UNDELIVERED) {
          e.text = `${e.text} ${i18n.t(
            "errorMessages.packet-undelivery-failed"
          )}`;
          break;
        }
      }
      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return response;
  },

  async printPacketLabels({ commit, dispatch }, barcodesAndType) {
    let prints;
    try {
      commit("setLoading", true, { root: true });
      const response = await PacketService.postPrintPacketLabels(
        barcodesAndType
      );
      prints = response.data.prints;
      dispatch("showPrintErrorsIfAvailable", prints, { root: true });
    } catch (error) {
      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return prints;
  },

  async deliverReturnPackets({ commit, dispatch }, codeAndBarcodes) {
    let response;
    try {
      commit("setLoading", true, { root: true });
      response = await PacketService.postReturnPacketsDeliver(codeAndBarcodes);
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorReturnPacket",
        error
      );

      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return response;
  },

  async reprintLabel({ commit, dispatch }, reprintId) {
    let prints;
    try {
      commit("setLoading", true, { root: true });
      const response = await PacketService.postReprintLabel({
        reprintId
      });
      prints = response.data.prints;
      dispatch("showPrintErrorsIfAvailable", prints, { root: true });
    } catch (error) {
      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return prints;
  }
};

const mutations = {
  setReturnShipmentType(state, returnShipmentType) {
    state.returnShipmentType = returnShipmentType;
  },

  setPacket(state, packet) {
    state.packet = packet;
    this.commit("clearErrors");
  },

  setSender(state, sender) {
    state.sender = sender;
    this.commit("clearErrors");
  },

  setPackets(state, packets) {
    state.packets = packets;
    this.commit("clearErrors");
  },

  setEshopName(state, eshopName) {
    state.eshopName = eshopName;
    this.commit("clearErrors");
  },

  setReprintId(state, reprintId) {
    state.reprintId = reprintId;
    this.commit("clearErrors");
  },

  refreshReadyToPick(state) {
    state.readyToPick =
      state.packet.isPaidByCard ||
      state.packet.cod == 0 ||
      state.paidInCash == true;
    this.commit("clearErrors");
  },

  setPacketReceived(state, packetReceived) {
    state.packetReceived = packetReceived;
    this.commit("clearErrors");
  },

  setPacketPaid(state, isPaid) {
    state.packet.isPaidByCard = isPaid;
    this.commit("clearErrors");
  },

  setPaidWithDevice(state, isPaid) {
    state.paidWithDevice = isPaid;
    this.commit("clearErrors");
  },

  setPacketBarcode(state, packetBarcode) {
    state.packetBarcode = packetBarcode;
    this.commit("clearErrors");
  },

  setPaidInCash(state, paidInCash) {
    state.paidInCash = paidInCash;
    this.commit("clearErrors");
  },

  setReturnShipmentPrints(state, returnShipmentPrints) {
    state.returnShipmentPrints = returnShipmentPrints;
    this.commit("clearErrors");
  },

  setReadyForUndelivery(state, isReady) {
    state.readyForUndelivery = isReady;
  },

  setToggleSelectionClicked(state, toggleSelectionClicked) {
    state.toggleSelectionClicked = toggleSelectionClicked;
  },

  setScannedBarcode(state, scannedBarcode) {
    state.scannedBarcode = scannedBarcode;
  },

  setSearchCode(state, searchCode) {
    state.searchCode = searchCode;
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
