import i18n from "@/i18n";
import _ from "lodash";

export default function mapTranslationToCode(translationKey, error, param) {
  const errors = error.response.data.errors;
  const arrayErrors = _.isArray(errors) ? errors : [errors];

  const newArrayErrors = arrayErrors.map(e => {
    if (e.code && i18n.te(`${translationKey}.${e.code}`)) {
      if (e.code === "batchNotFound") {
        e.text = i18n.t("apiErrorSReader.batchNotFound", { batchId: param });
      } else {
        e.text = i18n.t(`${translationKey}.${e.code}`);
      }
    } else {
      e.text = i18n.t("errorMessages.something-has-broken");
    }

    return e;
  });

  return newArrayErrors;
}
