import { PaymentService } from "@/util/payment.service";
import i18n from "@/i18n";
import mapTranslationToCode from "@/util/translation";

export const CANCELLATION_STRATEGY_TYPE = {
  ABORT: "abort",
  REVERSAL: "reversal",
  REFUND: "refund"
};

export const ERRORS = {
  CANCELLATION_ERROR: "cancellationError"
};

const getDefaultState = () => {
  return {
    transactionId: 0,
    newTransactionId: 0
  };
};

const state = getDefaultState();

const getters = {};

const actions = {
  async getCardTerminalId({ commit, dispatch }) {
    let transactionId;
    try {
      commit("setLoading", true, { root: true });
      const response = await PaymentService.createCardTerminalId();
      transactionId = response.data.cardTransaction.id;
      commit("setTransactionId", transactionId);
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorCardTerminal",
        error
      );

      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return transactionId;
  },

  async createCardPayment({ commit, dispatch }, barcodeAndAmount) {
    let response;
    try {
      // TODO (jana): remove when solved through independent modules
      commit("resetState");

      commit("setLoading", true, { root: true });
      const transactionId = await dispatch("getCardTerminalId");

      if (transactionId) {
        response = await PaymentService.postCardTerminal(
          transactionId,
          barcodeAndAmount
        );
      }
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorCardTerminal",
        error
      );

      // TODO (jana): remove when solved through independent modules
      // We need to reset state when transaction creation failed
      commit("resetState");
      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return response;
  },

  async getCardTransactionDetail({ commit, dispatch }, barcode) {
    let isPaid;
    try {
      commit("setLoading", true, { root: true });
      const response = await PaymentService.getCardTransactionDetail(
        state.transactionId
      );
      const data = response.data;
      const cardTransactionStatus = data.cardTransaction.status;

      if (cardTransactionStatus.success && cardTransactionStatus.finished) {
        // Find packet with same barcode
        const packet = data.embedded.packet.find(
          actual => actual.barcode == barcode
        );

        if (packet && packet.isPaidByCard) {
          isPaid = true;
          commit("packet/setPacketPaid", isPaid, { root: true });
          commit("packet/refreshReadyToPick", null, { root: true });
        } else {
          // Dispatch and throw default error
          dispatch(
            "setErrorOrThrow",
            i18n.t("errorMessages.please-contact-support"),
            { root: true }
          );
        }
      } else {
        dispatch("setErrorOrThrow", cardTransactionStatus.message, {
          root: true
        });
      }
    } catch (error) {
      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return isPaid;
  },

  async getCardTransactionStatus({ commit, dispatch }, barcode) {
    let isDone;
    try {
      commit("setLoading", true, { root: true });
      const response = await PaymentService.getCardTransactionDetail(
        state.newTransactionId ? state.newTransactionId : state.transactionId // for abort use normal transaction id
      );
      const data = response.data;
      const cardTransactionStatus = data.cardTransaction.status;

      if (cardTransactionStatus.success && cardTransactionStatus.finished) {
        // Find packet with same barcode
        const packet = data.embedded.packet.find(
          actual => actual.barcode == barcode
        );

        if (packet) {
          isDone = true;
        } else {
          isDone = false;
          // Dispatch and throw default error
          dispatch(
            "setErrorOrThrow",
            i18n.t("errorMessages.please-contact-support"),
            { root: true }
          );
        }
      } else {
        dispatch("setErrorOrThrow", cardTransactionStatus.message, {
          root: true
        });
      }
    } catch (error) {
      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return isDone;
  },

  async abortTransaction({ commit, dispatch }) {
    let response;
    try {
      commit("setLoading", true, { root: true });
      response = await PaymentService.postCardTransactionAbort(
        state.transactionId
      );
    } catch (error) {
      dispatch("setErrorOrThrow", error, { root: true });
      throw error;
    } finally {
      commit("setLoading", false, { root: true });
    }
    return response;
  },

  async reversalTransaction({ commit, dispatch }) {
    let response;
    try {
      commit("setLoading", true, { root: true });
      response = await PaymentService.postCardTransactionReversal(
        state.transactionId,
        state.newTransactionId
      );
    } catch (error) {
      dispatch("setErrorOrThrow", error, { root: true });
      throw error;
    } finally {
      commit("setLoading", false, { root: true });
    }
    return response;
  },

  async refundTransaction({ commit, dispatch }) {
    let response;
    try {
      commit("setLoading", true, { root: true });
      response = await PaymentService.postCardTransactionRefund(
        state.transactionId,
        state.newTransactionId
      );
    } catch (error) {
      dispatch("setErrorOrThrow", error, { root: true });
      throw error;
    } finally {
      commit("setLoading", false, { root: true });
    }
    return response;
  },

  async createWebPaymentLink({ commit, dispatch }, barcode) {
    let response;
    try {
      // TODO (jana): remove when solved through independent modules
      commit("resetState");

      commit("setLoading", true, { root: true });
      response = await PaymentService.postPaymentLink(barcode);
      commit("clearErrors", true, { root: true });
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorWebPaymentLink",
        error
      );

      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return response;
  },

  async getCodInfo({ commit, dispatch }, barcode) {
    let isPaid;
    try {
      commit("setLoading", true, { root: true });
      const response = await PaymentService.getPacketCodInfo(barcode);
      isPaid = response.data.paidByCard;

      if (isPaid) {
        commit("packet/setPacketPaid", isPaid, { root: true });
        commit("packet/refreshReadyToPick", null, { root: true });
      }
    } catch (error) {
      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return isPaid;
  },

  async cancelTransactionBasedOnStrategy({ commit, dispatch }, barcode) {
    try {
      commit("setLoading", true, { root: true });
      const transactionId = await dispatch(
        "getCurrentOrLastTransactionId",
        barcode
      );
      const response = await PaymentService.getCancellationStrategy(
        transactionId
      );
      const cancellationStrategy = response.data.cancellationStrategy;
      if (cancellationStrategy.isCancellable) {
        switch (cancellationStrategy.strategy) {
          case CANCELLATION_STRATEGY_TYPE.ABORT:
            return await dispatch("abortTransaction");
          case CANCELLATION_STRATEGY_TYPE.REVERSAL:
            return await dispatch(
              "getIdAndCancelTransaction",
              "reversalTransaction"
            );
          case CANCELLATION_STRATEGY_TYPE.REFUND:
            return await dispatch(
              "getIdAndCancelTransaction",
              "refundTransaction"
            );
        }
      } else {
        dispatch(
          "setErrorOrThrow",
          {
            code: ERRORS.CANCELLATION_ERROR,
            text: i18n.t("errorMessages.contact-support")
          },
          {
            root: true
          }
        );
      }
    } catch (error) {
      dispatch("setErrorOrThrow", error, { root: true });
      throw error;
    } finally {
      commit("setLoading", false, { root: true });
    }
  },

  async cancelTransactionWithRetry({ dispatch }, barcode) {
    const MAX_ITERATIONS = 5;
    let response;

    for (let i = 0; i < MAX_ITERATIONS; i++) {
      try {
        response = await dispatch("cancelTransactionBasedOnStrategy", barcode);
        if (response) {
          break;
        }
      } catch (error) {
        if (i === MAX_ITERATIONS - 1) {
          // Set error only if last iteration fails.
          dispatch(
            "setErrorOrThrow",
            {
              code: ERRORS.CANCELLATION_ERROR,
              text: i18n.t("errorMessages.contact-support")
            },
            {
              root: true
            }
          );
        }
      }
    }

    return response;
  },

  async cancelCardPaymentTransaction({ commit, dispatch }, barcode) {
    await dispatch("cancelTransactionWithRetry", barcode);
    // TODO (jana): remove when solved through independent modules
    // We need to reset state of the payment here
    commit("resetState");
  },

  async getIdAndCancelTransaction({ commit, dispatch }, type) {
    const response = await PaymentService.createCardTerminalId();
    const newId = response.data.cardTransaction.id;
    commit("setNewTransactionId", newId);
    return dispatch(type);
  },

  async getLastTransactionId({ commit, dispatch }, barcode) {
    let lastTransactionId;
    try {
      commit("setLoading", true, { root: true });
      const response = await PaymentService.getTransactionPacket(barcode);
      const lastTransaction = response.data.lastTransaction;
      if (lastTransaction.packetBarcode == barcode) {
        lastTransactionId = lastTransaction.transactionId;
        commit("setTransactionId", lastTransactionId);
      }
    } catch (error) {
      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return lastTransactionId;
  },

  async getCurrentOrLastTransactionId({ dispatch }, barcode) {
    if (state.transactionId) {
      return state.transactionId;
    }
    return await dispatch("getLastTransactionId", barcode);
  },

  payInCash({ commit }) {
    commit("packet/setPaidInCash", true, { root: true });
    commit("packet/refreshReadyToPick", null, { root: true });
  }
};

const mutations = {
  setTransactionId(state, id) {
    state.transactionId = id;
    this.commit("clearErrors");
  },

  setNewTransactionId(state, newId) {
    state.newTransactionId = newId;
    this.commit("clearErrors");
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
