import ApiService from "@/util/api.service";

export const ScannedService = {
  postReceivePacketProcess(batchId) {
    return ApiService.post(
      `sreader/batch/${batchId}/ppa-receive-packet/process`
    );
  }
};

export default ScannedService;
