import ApiService from "@/util/api.service";

export const PacketService = {
  postSearchType(text) {
    return ApiService.post("search-type", { subject: text });
  },

  getPacketDetail(text) {
    return ApiService.get(`packet/delivery-detail/${text}?embed=sender`);
  },

  getReturnPackets(text) {
    return ApiService.get(`return-packet/list/${text}?embed=sender`);
  },

  receiveC2CPacket(text, type) {
    return ApiService.post(`packet/${type}/receive`, { password: text });
  },

  getReturnShipment(textAndType) {
    return ApiService.get(
      `packet/return-shipment/receive?${textAndType.returnShipmentType}=${textAndType.code}`
    );
  },

  postReturnShipment(codeAndCustomer) {
    return ApiService.post("packet/return-shipment/receive", codeAndCustomer);
  },

  postPacketDeliver(data) {
    return ApiService.post("packet/deliver", data);
  },

  postPacketCancelDelivery(params) {
    return ApiService.post("packet/cancel-delivery", params);
  },

  postPrintPacketLabels(barcodesAndType) {
    return ApiService.post("print/packet/labels", barcodesAndType);
  },

  postReturnPacketsDeliver(codeAndBarcodes) {
    return ApiService.post("return-packet/deliver", {
      ...codeAndBarcodes
    });
  },

  postReprintLabel(id) {
    return ApiService.post("print/reprint", id);
  }
};

export default PacketService;
