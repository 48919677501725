import { ScannedService } from "@/util/scanned.service";
import mapTranslationToCode from "@/util/translation";

const getDefaultState = () => {
  return {
    packets: [],
    reprintId: 0
  };
};

const state = getDefaultState();

const getters = {};

const actions = {
  async processPacketsReceive({ commit, dispatch }, batchId) {
    let response;
    try {
      commit("setLoading", true, { root: true });
      response = await ScannedService.postReceivePacketProcess(batchId);
      const data = response.data;
      const prints = data.prints;

      commit("setPackets", data.items);

      if (prints && prints[0]) {
        commit("setReprintId", prints[0].reprintId);
        dispatch("showPrintErrorsIfAvailable", prints, { root: true });
      }
    } catch (error) {
      error.response.data.errors = mapTranslationToCode(
        "apiErrorSReader",
        error,
        batchId
      );

      dispatch("setErrorOrThrow", error, { root: true });
    } finally {
      commit("setLoading", false, { root: true });
    }
    return response;
  }
};

const mutations = {
  setPackets(state, packets) {
    state.packets = packets;
    this.commit("clearErrors");
  },

  setReprintId(state, reprintId) {
    state.reprintId = reprintId;
    this.commit("clearErrors");
  },

  resetState(state) {
    Object.assign(state, getDefaultState());
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
