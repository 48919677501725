import ApiService from "@/util/api.service";

export const PaymentService = {
  createCardTerminalId() {
    return ApiService.post("card-terminal/id");
  },

  postCardTerminal(transactionId, barcodeAndAmount) {
    return ApiService.post("card-terminal", {
      id: transactionId,
      type: "sale",
      ...barcodeAndAmount
    });
  },

  getCardTransactionDetail(id) {
    return ApiService.get(`card-terminal/${id}/detail?embed=packet`);
  },

  postCardTransactionAbort(id) {
    return ApiService.post(`card-terminal/${id}/abort`);
  },

  postCardTransactionReversal(id, newId) {
    return ApiService.post(`card-terminal/${id}/reversal`, { id: newId });
  },

  postCardTransactionRefund(id, newId) {
    return ApiService.post(`card-terminal/${id}/refund`, { id: newId });
  },

  postPaymentLink(barcode) {
    return ApiService.post("packet/web-payment-link", { barcode: barcode });
  },

  getPacketCodInfo(barcode) {
    return ApiService.get(`packet/cod-info/${barcode}`);
  },

  getCancellationStrategy(id) {
    return ApiService.get(`card-terminal/${id}/cancellation-strategy`);
  },

  getTransactionPacket(barcode) {
    return ApiService.get(`card/transaction-packet/${barcode}`);
  }
};

export default PaymentService;
