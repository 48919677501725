import Vue from "vue";
import axios from "axios";
import API_URL from "./constants";
import StorageService from "./storage.service";
import getActualLanguage from "./language";

export const ApiService = {
  init() {
    axios.defaults.baseURL = API_URL;
  },

  setLanguageHeader() {
    axios.defaults.headers.common["Accept-Language"] = getActualLanguage();
  },

  setHeaders() {
    Vue.$log.info("Setting headers");

    const token = StorageService.getToken();
    if (token) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    }
  },

  async get(resource) {
    try {
      Vue.$log.info(`GET ${resource}`);
      this.setHeaders();
      return await axios.get(resource);
    } catch (error) {
      Vue.$log.error(`ApiService error for GET ${resource}`);
      throw error;
    }
  },

  async post(resource, params, headers) {
    try {
      Vue.$log.info(`POST ${resource}`);
      this.setHeaders();
      return await axios.post(resource, params, headers);
    } catch (error) {
      Vue.$log.error(`ApiService error for POST ${resource}`);
      throw error;
    }
  },

  async put(resource, params, headers) {
    try {
      Vue.$log.info(`PUT ${resource}`);
      this.setHeaders();
      return await axios.put(resource, params, headers);
    } catch (error) {
      Vue.$log.error(`ApiService error for PUT ${resource}`);
      throw error;
    }
  },

  async delete(resource) {
    try {
      Vue.$log.info(`DELETE ${resource}`);
      this.setHeaders();
      return await axios.delete(resource);
    } catch (error) {
      Vue.$log.error(`ApiService error for DELETE ${resource}`);
      throw error;
    }
  }
};

export default ApiService;
