import axios from "axios";
import _ from "lodash";
import i18n from "@/i18n";
import store from "@/store";
import router from "@/router";
import AuthService from "@/util/auth.service";
import StorageService from "@/util/storage.service";
import { MOBILE_TYPES } from "@/util/mobile.type";
import { getEnvURL } from "@/util/constants";
import mapTranslationToCode from "@/util/translation";

export default function setAuthTokenRefreshing() {
  let retryCount = 0;
  const MAX_RETRY_COUNT = 4;

  axios.interceptors.response.use(
    response => {
      retryCount = 0;

      return response;
    },
    async error => {
      const originalRequest = error.config;

      if (!error.response && retryCount < MAX_RETRY_COUNT) {
        retryCount++;

        return axios(originalRequest);
      } else if (error.response && error.response.status) {
        const currentToken = StorageService.getToken();

        if (
          error.response.status === 401 &&
          !originalRequest._retry &&
          currentToken
        ) {
          try {
            originalRequest._retry = true;
            const refreshToken = StorageService.getRefreshToken();

            const response = await AuthService.refreshAuthToken(refreshToken);
            const tokenData = response.data.token;
            const deviceGateway = response.data.deviceGateway;
            const newToken = tokenData.accessToken;
            const newRefreshToken = tokenData.refreshToken;

            StorageService.saveToken(newToken);
            StorageService.saveRefreshToken(newRefreshToken);

            if (deviceGateway) {
              StorageService.saveDeviceGateway(deviceGateway.pairingActive);
            }

            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;

            return axios(originalRequest);
          } catch (error) {
            logout(error);
          }
        } else if (error.response.status >= 500) {
          if (store.state.isMobile) {
            if (store.state.platform == MOBILE_TYPES.ANDROID) {
              window.PacketaPPA.openEPD();
            } else if (store.state.platform == MOBILE_TYPES.IOS) {
              window.webkit.messageHandlers.openEPD.postMessage({});
            }
          } else {
            if (window.confirm(i18n.t("confirmMessageNVZ"))) {
              const nvzToken = StorageService.getNVZToken();

              if (nvzToken) {
                let temporaryToken;

                try {
                  const response = await AuthService.getTemporaryNVZToken(
                    nvzToken
                  );
                  const data = response.data;

                  temporaryToken = data ? data.sign_in_token : null;
                } finally {
                  switchToNVZ(temporaryToken);
                }
              } else {
                switchToNVZ();
              }
            }
          }
        }
      }

      return Promise.reject(error);
    }
  );
}

function logout(error) {
  if (store.state.isMobile) {
    store.dispatch("auth/logout", { root: true });

    if (store.state.platform == MOBILE_TYPES.ANDROID) {
      window.PacketaPPA.onLoggedOut();
    } else if (store.state.platform == MOBILE_TYPES.IOS) {
      window.webkit.messageHandlers.onLoggedOut.postMessage({});
    }
  } else {
    error.response.data.errors = mapTranslationToCode("apiErrorToken", error);
    store.dispatch("setErrorOrThrow", error, { root: true });

    _.delay(() => {
      store.dispatch("auth/logout", { root: true });
      router.replace({ name: "login" });
    }, 4000);
  }
}

function switchToNVZ(temporaryToken) {
  store.dispatch("auth/logout", { root: true });
  const paramToken = `?sign_in_token=${temporaryToken}`;

  window.location.replace(
    `${getEnvURL()}/sign_in${temporaryToken ? paramToken : ""}`
  );
}
